import styled from "styled-components";

export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #158cba;
    color: #fff
`